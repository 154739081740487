<template>
  <div>
    <div id="EmployeesAccount">
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("我的收货地址") }}</div>
        <div class="flex orderScreenContainer">
          <div class="orderScreenBtnListContainer flexAndCenter" style="margin-bottom:-6px">
            <div @click="deleteAddress">{{ $fanyi("删除") }}</div>
            <div @click="addOrEditAddressDialogVisible = true; updateStatus = false; title = '住所'">{{
                $fanyi("添加地址")
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="addressListContainer flex">
        <div v-for="(item, index) in addressList" :key="index" class="addressContainer">
          <div class="addressHeaderContainer flex">
            <input v-model="item.checked" style="margin:0" type="checkbox"/>
          </div>
          <div class="addressName u-line">{{ item.title }}</div>
          <div class="addressMessageListContainer">
            <div class="addressMessageContainer flexAndCenter">
              <div>{{ $fanyi("氏名") }}：</div>
              <div class="u-line cursorPointer" :title="item.name">{{ item.name }}</div>
            </div>
            <div class="addressMessageContainer flexAndCenter">
              <div>{{ $fanyi("公司名") }}：</div>
              <div class="u-line cursorPointer" :title="item.company">{{ item.company }}</div>
            </div>
            <div class="addressMessageContainer flexAndCenter">
              <div>{{ $fanyi("住所") }}：</div>
              <div class="u-line cursorPointer" :title="item.address">{{ item.address }}</div>
            </div>
            <div class="addressMessageContainer flexAndCenter">
              <div>{{ $fanyi("邮编") }}：</div>
              <div class="u-line cursorPointer" :title="item.zip">{{ item.zip }}</div>
            </div>
            <div class="addressMessageContainer flexAndCenter">
              <div>{{ $fanyi("手机") }}：</div>
              <div class="u-line cursorPointer" :title="item.mobile">{{ item.mobile }}</div>
            </div>
            <div class="addressMessageContainer flexAndCenter">
              <div>{{ $fanyi("座机") }}：</div>
              <div class="u-line cursorPointer" :title="item.tel">{{ item.tel }}</div>
            </div>
            <div class="addressMessageContainer flexAndCenter">
              <div>{{ $fanyi("法人番号") }}：</div>
              <div class="u-line cursorPointer" :title="item.corporate_name">{{ item.corporate_name }}</div>
            </div>
            <div class="addressMessageContainer flexAndCenter">
              <div>{{ $fanyi("リアルタイム口座") }}：</div>
              <div class="u-line cursorPointer" :title="item.account">{{ item.account }}</div>
            </div>
            <div class="addressMessageContainer flexAndCenter">
              <div>{{ $fanyi("標準コード") }}：</div>
              <div class="u-line cursorPointer" :title="item.standard_code">{{ item.standard_code }}</div>
            </div>
          </div>
          <div class="btn" @click="getUpdateAddressMessage(item)">{{ $fanyi("编辑") }}</div>
        </div>
      </div>
      <div class="addOrEditAddressContainer">
        <el-dialog :title="updateStatus === true ? $fanyi('编辑地址') : $fanyi('新增地址')"
                   :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false"
                   :visible.sync="addOrEditAddressDialogVisible" center width="1050px">
          <div class="addOrEditAddressContainerHeaderContainer flexAndCenter">
            <el-input v-model="title"/>
            <div @click="thirdPartyWarehouseDialogVisible = true">{{ $fanyi('从仓库选择地址') }}</div>
          </div>
          <div class="flex" style="justify-content: space-between">
            <el-form class="firStepForm" :model="formLeft" :rules="formLeftRules" ref="formRef" label-position="right"
                     label-width="130px" style="margin-top: 26px">
              <el-form-item prop="name" :label="$fanyi('氏名') + '：'">
                <div class="formInput">
                  <el-input v-model="formLeft.name"/>
                </div>
              </el-form-item>
              <el-form-item prop="address" :label="$fanyi('住所') + '：'">
                <div class="formInput">
                  <el-input v-model="formLeft.address" :maxlength="255" resize="none" type="textarea"/>
                </div>
              </el-form-item>
              <el-form-item :label="$fanyi('公司名') + '：'">
                <div class="formInput newFormInput">
                  <el-input v-model="formLeft.company" :maxlength="255"/>
                </div>
              </el-form-item>
              <el-form-item prop="zip" :label="$fanyi('邮编') + '：'">
                <div class="formInput newFormInput">
                  <el-input class="ipt passWordInputBox" :maxlength="8" @input="zipChannelInputLimit"
                            v-model="formLeft.zip"/>
                </div>
              </el-form-item>
              <el-form-item :label="$fanyi('座机') + '：'">
                <div class="formInput newFormInput">
                  <el-input v-model="formLeft.tel" class="ipt passWordInputBox"/>
                </div>
              </el-form-item>
              <el-form-item :label="$fanyi('手机') + '：'" prop="mobile">
                <div class="formInput newFormInput">
                  <el-input class="ipt passWordInputBox" type="number" v-model="formLeft.mobile"
                            @keydown.native="mobileChannelInputLimit"/>
                </div>
              </el-form-item>
            </el-form>
            <el-form class="firStepForm" :model="formRight" :rules="formRightRules" ref="newFormRef"
                     label-position="right" label-width="180px" style="margin-top: 26px">
              <el-form-item :label="$fanyi('ローマ字') + `(${$fanyi('氏名')})` + '：'">
                <div class="formInput">
                  <el-input v-model="formRight.name_rome"/>
                </div>
              </el-form-item>
              <el-form-item :label="$fanyi('ローマ字') + `(${$fanyi('住所')})` + '：'">
                <div class="formInput">
                  <el-input v-model="formRight.address_rome" resize="none" type="textarea"/>
                </div>
              </el-form-item>
              <el-form-item :label="$fanyi('法人番号') + '：'" prop="corporate_name">
                <div class="formInput">
                  <el-input v-model="formRight.corporate_name"/>
                </div>
              </el-form-item>
              <el-form-item :label="$fanyi('リアルタイム口座(14桁)') + '：'" prop="account">
                <div class="formInput newFormInput">
                  <el-input v-model="formRight.account" type="number" @keydown.native="mobileChannelInputLimit"/>
                </div>
              </el-form-item>
              <el-form-item :label="$fanyi('輸出入者コード') + '：'">
                <div class="formInput newFormInput">
                  <el-input v-model="formRight.standard_code"/>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addOrEditAddressDialogVisible = false; deleteData()">{{ $fanyi("リセット") }}</el-button>
            <el-button type="primary" @click="saveAddress">{{ $fanyi("保存") }}</el-button>
          </span>
        </el-dialog>
        <!--        仓库地址弹窗-->
        <el-dialog :close-on-click-modal="false" :visible.sync="thirdPartyWarehouseDialogVisible" width="980px"
                   :before-close="handleClose" :close-on-press-escape="false" center>
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane :label="$fanyi(item.group_name)" :name="item.group_name"
                         v-for="(item, index) in thirdPartyWarehouseTabList" :key="index">
            </el-tab-pane>
          </el-tabs>
          <div class="thirdPartyWarehouseListContainer" v-show="thirdPartyWarehouseList.length > 0">
            <div v-for="(item, index) in thirdPartyWarehouseList" :key="index"
                 class="thirdPartyWarehouseContainer flexAndCenter">
              <!--              仓库编号-->
              <div>{{ item.sn }}</div>
              <!--              仓库名-->
              <div class="name">{{ item.name }}</div>
              <!--              仓库地址-->
              <div class="address">{{ item.address_jpn }}</div>
              <!--              仓库电话-->
              <div class="zip">{{ item.mobile }}</div>
              <!--              仓库邮编-->
              <div class="zip">{{ item.zip }}</div>
              <div class="flexAndCenter">
                <div @click="getThirdPartyWarehouseMessage(item)">{{ $fanyi("选择") }}</div>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: '',
      updateStatus: false,
      addOrEditAddressDialogVisible: false,
      thirdPartyWarehouseDialogVisible: false,
      formLeft: {
        name: '',
        company: '',
        address: '',
        zip: '',
        mobile: '',
        tel: ''
      },
      title: '',
      addressId: 0,
      formRight: {
        name_rome: '',
        address_rome: '',
        corporate_name: '',
        account: '',
        standard_code: '',
      },
      thirdPartyWarehouseTabList: [],
      thirdPartyWarehouseList: [],
      formLeftRules: {
        name: [
          {
            required: true,
            message: this.$fanyi("请输入氏名"),
            trigger: "blur",
          },
          {
            min: 1,
            max: 99,
            message: this.$fanyi("长度不能超出99个字符"),
          }
        ],
        address: [{
          required: true,
          message: this.$fanyi("请输入住所"),
          trigger: "blur",
        }],
        zip: [{
          required: true,
          message: this.$fanyi("请输入邮编"),
          trigger: "blur",
        },
          {
            min: 7,
            max: 8,
            message: this.$fanyi("邮编格式不正确"),
          }],
        mobile: [
          {
            required: true,
            message: this.$fanyi("手机号为空"),
            trigger: "blur",
          },
        ],
      },
      formRightRules: {
        account: [
          {
            pattern: /^(\w){14}$/,
            message: this.$fanyi("格式不正确"),
          },
        ],
        corporate_name: [
          {
            pattern: /^[A-Za-z0-9]+$/,
            message: '13桁の数字で記入してください',
          }
        ]
      },
      addressList: []
    };
  },
  created() {
    this.$api.thirdPartyWarehouseList().then((res) => {
      this.thirdPartyWarehouseTabList = res.data;
      this.activeName = res.data[0].group_name;
      this.thirdPartyWarehouseList = res.data[0].result;
    })
    this.getData();
  },
  methods: {
    // 获取数据
    getData() {
      this.$api.userAddressList({
        page: 1,
        pageSize: 9999
      }).then((res) => {
        res.data.data.forEach((item) => {
          item.checked = false;
        })
        this.addressList = res.data.data;
      })
    },
    //保存收货地址
    saveAddress() {
      if (this.title === '') {
        this.$message.error(this.$fanyi('请输入地址标题'));
        return
      }
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$refs.newFormRef.validate((valid) => {
            if (valid) {
              if (((this.formLeft.company !== '' && this.formLeft.company != null) && (this.formRight.corporate_name == '' || this.formRight.corporate_name == null)) || ((this.formRight.corporate_name !== '' && this.formRight.corporate_name != null) && (this.formLeft.company == '' || this.formLeft.company == null))) {
                this.$message({type: "error", message: '会社名と法人番号の両方をご記入下さい。'});
                return
              }
              let data = {
                title: this.title,
                name: this.formLeft.name,
                name_rome: this.formRight.name_rome,
                company: this.formLeft.company,
                corporate_name: this.formRight.corporate_name,
                address: this.formLeft.address,
                address_rome: this.formRight.address_rome,
                zip: this.formLeft.zip,
                mobile: this.formLeft.mobile,
                tel: this.formLeft.tel,
                account: this.formRight.account,
                standard_code: this.formRight.standard_code
              }
              if (this.updateStatus === true) {
                data.id = this.addressId
              }
              this.$api.userAddressUpdate(data).then((res) => {
                if (res.code != 0) return this.$message({type: "error", message: res.msg});
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.addOrEditAddressDialogVisible = false;
                this.deleteData();
                this.updateStatus = false;
                this.getData();
              })
            }
          });
        }
      });
    },
    //获取要编辑的地址详情
    getUpdateAddressMessage(item) {
      this.updateStatus = true;
      this.title = item.title;
      this.addressId = item.id;
      this.formLeft.name = item.name;
      this.formRight.name_rome = item.name_rome;
      this.formLeft.company = item.company;
      this.formRight.corporate_name = item.corporate_name;
      this.formLeft.address = item.address;
      this.formRight.address_rome = item.address_rome;
      this.formLeft.zip = item.zip;
      this.formLeft.mobile = item.mobile;
      this.formLeft.tel = item.tel;
      this.formRight.account = item.account;
      this.formRight.standard_code = item.standard_code;
      this.addOrEditAddressDialogVisible = true;
    },
    //获取选中的第三方仓库的地址信息
    getThirdPartyWarehouseMessage(item) {
      this.title = item.sn;
      this.formLeft.name = item.name;
      this.formLeft.address = item.address_jpn;
      this.formLeft.zip = item.zip;
      this.formLeft.mobile = item.mobile;
      this.thirdPartyWarehouseDialogVisible = false;
    },
    //邮编输入校验
    zipChannelInputLimit(e) {
      this.formLeft.zip = e.replace(/[^0-9- ]/g, '');
    },
    //删除选中的地址
    deleteAddress() {
      let arr = [];
      this.addressList.forEach((item) => {
        if (item.checked === true) {
          arr.push(item.id)
        }
      })
      if (arr.length > 0) {
        this.$confirm(
            this.$fanyi("确认删除选中的地址吗？"),
            this.$fanyi("提示"),
            {
              confirmButtonText: this.$fanyi("确定"),
              cancelButtonText: this.$fanyi("取消"),
              type: "warning",
              center: true,
            }).then(() => {
          this.$api.userAddressDelete({id: arr}).then((res) => {
            if (res.code != 0) return this.$message.error(res.msg);
            this.getData();
          });
        }).catch(() => {
          this.$message({
            type: "info",
            message: this.$fanyi("已取消删除")
          });
        });
      }
    },
    //更新仓库下的地址
    handleClick(tab) {
      for (let i = 0; i < this.thirdPartyWarehouseTabList.length; i++) {
        if (this.activeName === this.thirdPartyWarehouseTabList[i].group_name) {
          this.thirdPartyWarehouseList = this.thirdPartyWarehouseTabList[i].result;
          break;
        }
      }
    },
    //手机号过滤
    mobileChannelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "." || key === "。" || key == "-" || key == "-" || key == "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    //清空数据
    deleteData() {
      this.formRight = {
        name_rome: '',
        address_rome: '',
        corporate_name: '',
        account: '',
        standard_code: '',
      };
      this.formLeft = {
        name: '',
        company: '',
        address: '',
        zip: '',
        mobile: '',
        tel: ''
      };
      this.title = '';
    },
    handleClose(done) {
      this.$confirm(`${this.$fanyi("确认关闭")}`).then(_ => {
        done();
        this.deleteData();
      }).catch(_ => {

      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";


/deep/ input[type="checkbox"]:checked {
  background: #4A91E9;
}

.tableDataNullContainer {
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

#EmployeesAccount {
  background: #ffffff;
  border-radius: 10px;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .orderScreenContainer {
    //margin: 26px 26px 0 26px;
    justify-content: flex-end;


    .orderScreenBtnListContainer {
      div {
        border-radius: 8px;
        background: #B4272B;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }

      div:first-child {
        width: 76px;
        margin-right: 10px;
      }

      div:last-child {
        width: 100px;
      }
    }
  }

  .addressListContainer {
    flex-wrap: wrap;
    margin-top: 26px;

    .activeAddressContainer {
      border: 1px solid #B4272B !important;
      background: #FCF2F2 !important;
    }

    .addressContainer,
    .activeAddressContainer {
      position: relative;
      width: 335px;
      height: 394px;
      background: #fff;
      border: 1px solid #DFDFDF;
      border-radius: 10px;
      padding: 10px 10px 0;
      margin-left: 26px;
      margin-bottom: 26px;

      .addressHeaderContainer {
        justify-content: space-between;
        width: 100%;

        input[type="checkbox"]:checked {
          background: #4A91E9;
          border: none;
          width: 16px;
          height: 16px;
        }

        div {
          width: 100px;
          height: 30px;
          background: #FAF2F2;
          border: 1px solid #B4272B;
          border-radius: 6px;
          line-height: 30px;
          text-align: center;
          font-size: 12px;
          color: #B4272B;
        }
      }

      .addressName {
        color: #B4272B;
        font-size: 18px;
        font-weight: bold;
        margin-left: 20px;
        width: 308px;
        height: 24px;
      }

      .addressMessageListContainer {
        margin-top: 20px;

        .addressMessageContainer {
          margin-bottom: 8px;
          margin-left: 20px;
          font-size: 14px;
          color: #000;
          text-align: left;

          div:first-child {
            width: 130px;
            min-width: 130px;
          }
        }
      }

      .btn {
        margin-top: 20px;
        height: 34px;
        background: #B4272B;
        border-radius: 8px;
        text-align: center;
        line-height: 34px;
        cursor: pointer;
        margin-left: 20px;
        font-size: 14px;
        color: #fff;
      }
    }

  }

  .addOrEditAddressContainer {
    /deep/ .el-dialog {
      border-radius: 10px;
      padding: 30px;
      box-sizing: border-box;

      .el-dialog__header {
        padding: 0;

        .el-dialog__title {
          font-size: 18px;
          font-weight: bold;
          color: #333;
          padding-bottom: 7px;
          border-bottom: 4px solid #B4272B;
        }

        .el-dialog__headerbtn {
          top: 30px;
          font-size: 19px;

          .el-dialog__close {
            color: #B4272b;
          }
        }
      }

      .el-dialog__body {
        padding: 0;
      }

      .el-dialog__footer {
        padding: 0;

        button {
          border-radius: 8px;
          height: 34px;
          width: 120px;
          line-height: 34px;
          padding: 0;
          font-size: 14px;
        }

        button:first-child {
          color: #B4272B;
          border: 1px solid #B4272B;
          margin-right: 26px;
        }

        .el-button + .el-button {
          margin-left: 0;
        }
      }
    }

    .checkboxContainer {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 20px 0 30px;

      /deep/ .el-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 19px;

        .el-checkbox__inner {
          border-radius: 50%;
        }

        .el-checkbox__input {
          margin-bottom: -18px;
        }
      }
    }

    .addOrEditAddressContainerHeaderContainer {

      margin-top: 30px;

      /deep/ .el-input {
        width: 100px;

        .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
      }

      div:last-child {
        margin-left: 30px;
        border-radius: 8px;
        height: 34px;
        width: 120px;
        line-height: 34px;
        padding: 0;
        font-size: 14px;
        cursor: pointer;
        background: #B4272B;
        color: #fff;
        text-align: center;
      }
    }

    .formInput {
      /deep/ .el-input {
        width: 280px;

        .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
      }

      /deep/ .el-textarea {
        width: 280px;
      }
    }

    .thirdPartyWarehouseListContainer {
      border: 1px solid;

      .thirdPartyWarehouseContainer {
        border-bottom: 1px solid;
        height: 40px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid;
          height: 40px;
        }

        div:first-child {
          width: 100px;
        }

        .name {
          width: 250px;
        }

        .address {
          width: 250px;
        }

        .zip {
          width: 100px;
        }

        div:last-child {
          border-right: none;
          justify-content: center;
          flex: 1;

          div {
            width: 93px;
            max-width: 93px;
            height: 23px;
            line-height: 23px;
            text-align: center;
            cursor: pointer;
            color: #fff;
            border-radius: 3px;
            background: #B4272B;
          }
        }
      }

      .thirdPartyWarehouseContainer:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
